// -----------------------------
// CB Internation Phone Input
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/font.scss";
@import "../mixins/glyph.scss";
@import "../mixins/breakpoint.scss";

.cb-int-phone {
  min-width: 256px;
  .cb-int-phone-container {
    position: relative;

    .cb-flag-dropdown {
      position: absolute;
      top: -1px;
      border-radius: $btn-border-radius-input;
      width: 68px;
      width: 100%;
      transition: height $transition-time-s ease-in-out;

      .cb-flag-selected {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;

        height: 48px;
        padding: 0 15px;
        display: flex;

        align-items: center;
        justify-content: center;

        text-decoration: none;
        border-radius: $border-radius-input;

        border: 1px solid transparent;

        color: $black1;

        .cb-flag {
          // width: 16px;
          width: 40px;
          text-align: center;
        }

        &:visited {
          color: $black1;
        }
        &:focus {
          outline: 0;
          cursor: default;
          border: 1px solid $input-focus;
          box-shadow: inset 0px 0px 1px 0.5px rgba(48, 73, 207, 1);
          background-color: $white;
        }

        .cb-dropdown-toggle {
          font-size: 0.875rem !important;
          line-height: 16px;
          text-decoration: none !important;
          transition: all $transition-time-s ease-in-out;
          text-align: center;

          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
          }

          .cb-glyph,
          .cb-icon {
            color: $black1;
            font-size: 0.75rem;
            margin-left: 8px;
          }
        }
      }

      .cb-line {
        border-right: 1px solid $gray4;
        position: absolute;
        // left: 68px;
        left: 92px;
        top: 3px;
        width: 1px;
        height: 43px;
        display: block;
      }

      .cb-country-list {
        display: none;
        list-style: none;
        margin: 0;
        position: absolute;

        z-index: 3;
        top: 47px;
        left: 0;

        padding: 0 0 12px 0px;

        border-radius: $border-radius-input;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: $white;
        border: 1px solid transparent;

        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;

        li {
          > a {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            padding: 12px 24px;
            color: $black1;

            @include media-breakpoint-down("sm", $grid-breakpoints) {
              padding: 16px 24px;
            }

            text-decoration: none !important;
            transition: all $transition-time-s ease-in-out;
            white-space: nowrap;

            opacity: 0.4;

            &:hover,
            &:active,
            &:focus {
              opacity: 1;
              background-color: $gray5;
              box-shadow: inset 2px 0px 0px 0px $black1;
              outline: 0;
              color: $black1;
            }

            span {
              // &:not(.cb-flag) {
                // margin-left: 16px;
                font-size: 0.875rem;
                line-height: 1.1428571428571428em;
              // }
            }
            &.cb-selected {
              box-shadow: inset 2px 0px 0px 0px $black1;
            }
          }
        }
      }

      &.cb-open {
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
        height: 248px;

        .cb-flag-selected {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          background-color: $white;
        }

        .cb-line {
          display: none;
        }

        .cb-country-list {
          display: block;
        }

        &.cb-revers {
          top: -200px;

          .cb-flag-selected {
            top: 199px;
            border-radius: $border-radius-input;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }

          .cb-country-list {
            border-radius: $border-radius-input;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            top: 0;
          }
        }
      }

      &.cb-focus {
        .cb-line {
          display: none;
        }
      }
    }

    input {
      // padding-left: 84px;
      padding-left: 104px!important;
    }
    .cb-open {
      + input {
        border-color: white;
      }
    }
  }

  &.cb-input-condensed,
  &.cb-condensed {
    .cb-flag-dropdown {
      .cb-flag-selected {
        height: 40px;
      }

      .cb-country-list {
        top: 38px;
      }
    }
    .cb-line {
      height: 35px !important;
    }
  }
  &.cb-disabled {
    .cb-flag-dropdown {
      a {
        .cb-flag {
          filter: grayscale(100%);
          opacity: 0.4;
        }
        .cb-dropdown-toggle {
          .cb-glyph,
          .cb-icon {
            color: $cb-disabled-font-color !important;
          }
        }

        &:focus {
          border: 1px solid transparent !important;
          box-shadow: none !important;
          cursor: not-allowed !important;
          background-color: transparent !important;
        }
        &:hover {
          cursor: not-allowed !important;
        }
      }
    }
  }
}

//  extra treatment for floating label
.cb-floating-label {
  &.cb-int-phone {
    label {
      z-index: 2;
      width: calc(100% - 32px);
      justify-content: flex-start;
    }

    .cb-flag-dropdown {
      display: none;
    }

    input {
      padding-left: 16px;
    }

    .cb-value-fl,
    .cb-focus-fl {
      width: auto;
      justify-content: center;
      + .cb-int-phone-container {
        .cb-flag-dropdown {
          display: block;
        }

        input {
          padding-left: 84px;
        }
      }
    }
  }
}

// ------------------------------------ FLAGS
// .cb-flag {
//   background-image: url(../../assets/sprite-flags.png);
//   background-position: 0 -2145px;
//   width: 16px;
//   height: 11px;
//   display: block;
// }

// @each $value in $flags {
//   $i: index($flags, $value);
//   .#{$value}{
//     background-position: 0 -#{calc(($i - 1) * 11)}px;
//   }
// }

// flags2x are completely off 
// @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
// only screen and (-o-min-device-pixel-ratio: 3/2),
// only screen and (min-resolution: 144dpi),
// only screen and (min-resolution: 1.5dppx) {
// /* 1.25 dpr */
// // @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
// //   only screen and (min-resolution: 120dpi) {
//   .cb-flag {
//     background-image: url(../../assets/sprite-flags2x.png);
//     background-size: 16px 2156px;
//   }
// }


