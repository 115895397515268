// -----------------------------
// CB Horizontal Carousel
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";

.cb-horizontal-carousel {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  .cb-horizontal-carousel-nav {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: -ms-autohiding-scrollbar;
    position: relative;

    @include media-breakpoint-up("sm", $grid-breakpoints) {
      scrollbar-width: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .cb-horizontal-carousel-content {
      float: left;
      transition: transform $transition-time-s ease-in-out;

      &.cb-no-transition {
        transition: none;
      }
    }
  }

  .cb-horizontal-nav {
    height: 100%;
    position: absolute;
    top: 0;
    text-align: center;
    z-index: 1;
    cursor: default;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 0;
      text-decoration: none;
      font-size: 0.75rem;
      width: 24px;
      height: 100%;
      color: $white;
      transition: all $transition-time;
      background-color: $black1;
      opacity: 0.6;

      .cb-glyph,
      .cb-icon {
        transition: all $transition-time ease-in-out;
        font-size: 1rem;
        line-height: 1.5em;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        opacity: 0.8;
        width: 32px;

        .cb-glyph,
        .cb-icon {
          font-size: 1.5rem;
          line-height: 1em;
        }
      }
    }

    &.cb-horizontal-nav-right {
      right: 0;

      &[data-cb-overflow="both"],
      &[data-overflow="right"] {
        opacity: 1;
      }
    }
    &.cb-horizontal-nav-left {
      left: 0;

      &[data-cb-overflow="both"],
      &[data-overflow="left"] {
        opacity: 1;
      }
    }
  }
  &:not(.cb-horizontal-carousel-menu),
  &:not(.cb-horizontal-carousel-light),
  &:not(.cb-horizontal-carousel-dark) {
    .cb-horizontal-nav {
      opacity: 0;
      transition: opacity $transition-time ease-in-out;
    }
  }

  &[data-cb-overflow="both"],
  &[data-cb-overflow="right"] {
    .cb-horizontal-nav {
      &.cb-horizontal-nav-right {
        opacity: 1;

        a {
          cursor: pointer;
        }
      }
    }
  }

  &[data-cb-overflow="both"],
  &[data-cb-overflow="left"] {
    .cb-horizontal-nav {
      &.cb-horizontal-nav-left {
        opacity: 1;

        a {
          cursor: pointer;
        }
      }
    }
  }

  // IE issue
  .cb-event-cards-horizontal {
    &.cb-ie-fix {
      > ol {
        > li {
          min-width: 352px;
          min-height: 304px;
        }
      }
    }
  }

  // ------------------------------------ Option2

  $darkBg: rgba(255, 255, 255, 0.05);
  $lightBg: #f0f0f0;

  &.cb-horizontal-carousel-menu,
  &.cb-horizontal-carousel-menu-condensed,
  &.cb-horizontal-carousel-light,
  &.cb-horizontal-carousel-dark {
    ul {
      padding-top: 1px;
      li {
        a {
          white-space: nowrap;
        }
      }
    }

    .cb-horizontal-carousel-nav {
      overflow-x: auto;
      max-width: 100%;
    }

    .cb-horizontal-nav {
      width: 48px;
      opacity: 1 !important;
      a {
        width: 48px;
        font-size: 1.5rem;
        cursor: default;
        opacity: 1;
        display: flex;
        .cb-glyph,
        .cb-icon {
          font-size: 1.5rem;
          line-height: 1em;
          font-weight: 400;
        }

        &:hover,
        &:active,
        &:focus {
          color: inherit;
          .cb-glyph,
          .cb-icon {
            opacity: 0.8;
            font-size: 1.5rem;
            line-height: 1em;
            font-weight: 900;
          }
        }
      }

      &.cb-horizontal-nav-right {
        animation: $transition-time slideout-right forwards;
      }
      &.cb-horizontal-nav-left {
        animation: $transition-time slideout-left forwards;
      }
    }

    &[data-cb-overflow="both"],
    &[data-cb-overflow="right"] {
      .cb-horizontal-nav {
        &.cb-horizontal-nav-right {
          border-left: 1px solid $lightBg;
          box-shadow: -5px 0 11px -5px rgba(0, 0, 0, 0.1);
          transition: box-shadow $transition-time ease-in-out;
          clip-path: inset(0 -3px 0px -8px);

          animation: $transition-time slidein-right;
          &:hover,
          &:focus,
          &:active {
            box-shadow: -5px 0 11px -5px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    &[data-cb-overflow="both"],
    &[data-cb-overflow="left"] {
      .cb-horizontal-nav {
        &.cb-horizontal-nav-left {
          border-right: 1px solid $lightBg;
          box-shadow: 5px 0 11px -5px rgba(0, 0, 0, 0.1);
          transition: box-shadow $transition-time ease-in-out;
          clip-path: inset(0 -8px 0px 0);

          animation: $transition-time slidein-left;
          &:hover,
          &:focus,
          &:active {
            box-shadow: 5px 0 11px -5px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  &.cb-horizontal-carousel-dark {
    &[data-cb-overflow="both"],
    &[data-cb-overflow="right"] {
      .cb-horizontal-nav {
        &.cb-horizontal-nav-right {
          border-left: 1px solid $darkBg;
          box-shadow: -5px 0 11px -5px rgba(0, 0, 0, 0.2);
          &:hover,
          &:focus,
          &:active {
            box-shadow: -5px 0 11px -5px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    &[data-cb-overflow="both"],
    &[data-cb-overflow="left"] {
      .cb-horizontal-nav {
        &.cb-horizontal-nav-left {
          border-right: 1px solid $darkBg;
          box-shadow: 5px 0 11px -5px rgba(0, 0, 0, 0.2);
          &:hover,
          &:focus,
          &:active {
            box-shadow: 5px 0 11px -5px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }

  &.cb-horizontal-carousel-menu,
  &.cb-horizontal-carousel-menu-condensed {
    .cb-horizontal-carousel-nav {
      height: 72px;
      .cb-tabs-menu,
      .cb-menu-list {
        ul {
          li {
            margin-bottom: 4px;
          }
        }
      }
      &::-webkit-scrollbar {
        display: block !important;
        -webkit-appearance: none;

        &:horizontal {
          height: 4px;
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 0;
        // should match background, can't be transparent
        background-color: $gray4;
      }
    }

    .cb-horizontal-nav {
      a {
        font-size: 1rem;
        line-height: 1.5em;
        .cb-glyph,
        .cb-icon {
          font-size: 1rem;
          line-height: 1.5em;
        }
        &:hover,
        &:focus,
        &:active {
          .cb-glyph,
          .cb-icon {
            font-size: 1rem;
            line-height: 1.5em;
          }
        }
      }
    }
  }

  &.cb-horizontal-carousel-menu-condensed {
    .cb-horizontal-carousel-nav {
      height: 48px;
    }
  }
}
@include media-breakpoint-only("xs", $grid-breakpoints) {
  $darkBg: rgba(255, 255, 255, 0.05);
  $lightBg: #f0f0f0;
  .cb-horizontal-carousel {
    &.cb-horizontal-carousel-menu,
    &.cb-horizontal-carousel-light,
    &.cb-horizontal-carousel-dark {
      transition: box-shadow $transition-time ease-in-out;
      .cb-horizontal-carousel-nav {
        &::-webkit-scrollbar {
          display: block !important;
          -webkit-appearance: none;

          &:horizontal {
            height: 4px;
          }
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 0;
          // should match background, can't be transparent
          background-color: $gray4;
        }
      }
      .cb-horizontal-nav {
        &.cb-horizontal-nav-right {
          right: -16px;
        }
        &.cb-horizontal-nav-left {
          left: -16px;
        }
        a {
          display: none;
        }
      }
      &[data-cb-overflow="both"],
      &[data-cb-overflow="right"] {
        .cb-horizontal-nav {
          &.cb-horizontal-nav-right {
            border-left: 1px solid $lightBg;
            box-shadow: -5px 0 11px -5px rgba(0, 0, 0, 0.2);
            clip-path: inset(0 -3px 0px -8px);
            width: 16px;
          }
        }
      }

      &[data-cb-overflow="both"],
      &[data-cb-overflow="left"] {
        .cb-horizontal-nav {
          &.cb-horizontal-nav-left {
            border-right: 1px solid $lightBg;
            box-shadow: 5px 0 11px -5px rgba(0, 0, 0, 0.2);
            clip-path: inset(0 -8px 0px 0);

            width: 16px;
          }
        }
      }
    }

    &.cb-horizontal-carousel-dark {
      &[data-cb-overflow="both"],
      &[data-cb-overflow="right"] {
        .cb-horizontal-nav {
          &.cb-horizontal-nav-right {
            border-left: 1px solid $darkBg !important;
            box-shadow: -5px 0 11px -5px rgba(0, 0, 0, 0.3);
          }
        }
      }

      &[data-cb-overflow="both"],
      &[data-cb-overflow="left"] {
        .cb-horizontal-nav {
          &.cb-horizontal-nav-left {
            border-right: 1px solid $darkBg !important;
            box-shadow: 5px 0 11px -5px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}

[data-cb-input-m="keyboard"] {
  .cb-horizontal-carousel-menu,
  .cb-horizontal-carousel-light,
  .cb-horizontal-carousel-dark {
    .cb-horizontal-nav {
      a {
        &:hover,
        &:focus,
        &:active {
          outline: none !important;
          .cb-glyph,
          .cb-icon {
            position: relative;
            &::after {
              position: absolute;
              content: "";
              display: block;
              width: calc(100% + 24px);
              height: calc(100% + 24px);
              top: -12px;
              left: -12px;
              border-radius: inherit;
              box-shadow: inset 0 0 0 2px $blue5;
            }
          }
        }
      }
    }

    &.cb-horizontal-carousel-dark {
      .cb-horizontal-nav {
        a {
          &:hover,
          &:focus,
          &:active {
            .cb-glyph,
            .cb-icon {
              &::after {
                box-shadow: inset 0 0 0 2px $yellow1;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slidein-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideout-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slidein-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideout-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
