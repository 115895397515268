// -----------------------------
// CB Popover
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/font.scss";

.cb-popover {
  position: absolute;

  background-color: $white;
  color: $black1;

  min-width: $tooltip-min-width;
  max-width: $tooltip-max-width;
  border: 1px solid $tooltip-border-color;
  border-radius: $tooltip-border-radius;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  z-index: $zindex-tooltip;
  opacity: 0;
  transition: opacity $transition-time-l ease-in-out;
  text-align: left;

  //Mobile
  @include media-breakpoint-down("xs", $grid-breakpoints) {
    max-width: calc(100vw - 48px);
  }

  &[aria-hidden="false"] {
    display: block;
  }
  &[aria-hidden="true"] {
    display: none;
  }

  &[data-popper-placement^="top"],
  &[data-popper-placement^="bottom"],
  &[data-popper-placement^="right"],
  &[data-popper-placement^="left"] {
    opacity: 1;
  }

  .cb-popover-inner {
    padding: 24px;
    .cb-popover-header {
      margin-bottom: 24px;
      .cb-popover-title {
        font-weight: 400;

        font-size: 1.4375rem;
        line-height: 1.0434782608695652em;
      }
      &.cb-popover-close {
        display: flex;
        justify-content: space-between;
        .cb-popover-title {
          line-height: 32px;
        }
      }
    }
    .cb-popover-footer {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid $gray4;
      display: flex;
      justify-content: space-between;
      .cb-btn-naked {
        &.cb-no-padding {
          align-self: center;
        }
      }
    }
  }
}

.cb-btn-row {
  .cb-popover {
    font-size: 1rem;
    .cb-btn {
      flex-shrink: unset;
      margin: 0;
    }
  }
}

// React Component
.cb-drupal-popover {
  .cb-btn-circle {
    &:focus,
    &:hover {
      box-shadow: 0 0 0 2px $blue5;
      border: 1px solid $white;
    }
  }
  &.cb-popover-light {
    .cb-btn-circle {
      background-color: $white !important;
      border: 1px solid $white !important;
      color: $black1 !important;
      &:focus,
      &:hover {
        box-shadow: 0 0 0 2px $white;
        border: 1px solid $black1 !important;
      }
    }
  }
}
