// -----------------------------
// CB Table Mixins
// -----------------------------

@mixin table() {
  width: 100%;
  color: $black1;
  background-color: transparent; // Reset for nesting within parents with `background-color`.

  font-size: 1rem;
  line-height: 1.5em;
  >thead {
    background: $gray1;
    color: $white;

    >tr {
      >th {
        font-weight: 700;
      }
    }
  }
  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border: $table-border-width solid $table-border-color;
    min-width: 80px;
    &[rowspan] {
      vertical-align: middle;
    }
  }

  &.cb-table-light {
    > thead {
      background: $white;
      color: $black1;

      > tr {
        > th,
        > td {
          border-top-width: 2px;
          border-bottom-width: 2px;
          padding: $table-cell-padding-light;
        }
      }
    }
  }

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border: $table-border-width solid $table-border-color;
    min-width: 80px;
    &[rowspan] {
      vertical-align: middle;
    }
  }
  &.cb-table-no-stretch {
    width: auto;
  }
}