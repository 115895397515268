// -----------------------------
// CB Margin/Padding
// -----------------------------

// General
@each $value in $mpValue {
  .cb-margin-#{$value} {
    margin: #{$value}px;
  }

  @each $key in $mpList {
    .cb-margin-#{$key}-#{$value} {
      margin-#{$key}: #{$value}px;
    }
  }
}

// Per breakpoint
@each $breakpoint,
$value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $value in $mpValue {
      .cb-margin-#{$breakpoint}-#{$value} {
        margin: #{$value}px;
      }

      @each $key in $mpList {
        .cb-margin-#{$breakpoint}-#{$key}-#{$value} {
          margin-#{$key}: #{$value}px;
        }

        .cb-no-margin-#{$breakpoint}-#{$key} {
          margin-#{$key}: 0 !important;
        }
      }
    }
  }

  // Range
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $value in $mpValue {
      .cb-margin-#{$breakpoint}-up-#{$value} {
        margin: #{$value}px;
      }

      @each $key in $mpList {
        .cb-margin-#{$breakpoint}-up-#{$key}-#{$value} {
          margin-#{$key}: #{$value}px;
        }
      }
    }
  }

  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $value in $mpValue {
      .cb-margin-#{$breakpoint}-down-#{$value} {
        margin: #{$value}px;
      }

      @each $key in $mpList {
        .cb-margin-#{$breakpoint}-down-#{$key}-#{$value} {
          margin-#{$key}: #{$value}px;
        }
      }
    }
  }
}

.cb-no-margin {
  margin: 0 !important;
}

@each $key in $mpList {
  .cb-no-margin-#{$key} {
    margin-#{$key}: 0 !important
  }
}

@each $breakpoint,
$value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .cb-no-margin-#{$breakpoint} {
      margin: 0 !important;
    }
  }
}

// ------------------------------------ PADDING
// General
@each $value in $mpValue {
  .cb-padding-#{$value} {
    padding: #{$value}px;
  }

  @each $key in $mpList {
    .cb-padding-#{$key}-#{$value} {
      padding-#{$key}: #{$value}px;
    }
  }
}


// Per breakpoint
@each $breakpoint,
$value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $value in $mpValue {
      .cb-padding-#{$breakpoint}-#{$value} {
        padding: #{$value}px;
      }

      @each $key in $mpList {
        .cb-padding-#{$breakpoint}-#{$key}-#{$value} {
          padding-#{$key}: #{$value}px;
        }

        .cb-no-padding-#{$breakpoint}-#{$key} {
          padding-#{$key}: 0 !important;
        }
      }
    }
  }

  // Range
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $value in $mpValue {
      .cb-padding-#{$breakpoint}-up-#{$value} {
        padding: #{$value}px;
      }

      @each $key in $mpList {
        .cb-padding-#{$breakpoint}-up-#{$key}-#{$value} {
          padding-#{$key}: #{$value}px;
        }
      }
    }
  }

  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $value in $mpValue {
      .cb-padding-#{$breakpoint}-down-#{$value} {
        padding: #{$value}px;
      }

      @each $key in $mpList {
        .cb-padding-#{$breakpoint}-down-#{$key}-#{$value} {
          padding-#{$key}: #{$value}px;
        }
      }
    }
  }
}

.cb-no-padding {
  padding: 0 !important;
}

@each $key in $mpList {
  .cb-no-padding-#{$key} {
    padding-#{$key}: 0 !important
  }
}

@each $breakpoint,
$value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .cb-no-padding-#{$breakpoint} {
      padding: 0 !important;
    }
  }
}