// -----------------------------
// CB Spacer
// -----------------------------

.cb-spacerh,
spacerh {
  display: inline-block;
  width: #{$spacer-h}px;
}

@include spacer("h", $spacersH);
@include custom-spacer("h", $spacer-h);

// ------------------------------------ VERTICAL
.cb-spacerv,
spacerv {
  display: block;
  height: #{$spacer-v}px;
}

@include spacer("v", $spacersV);
@include custom-spacer("v", $spacer-v);
