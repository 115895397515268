// -----------------------------
// CB Multi Select
// -----------------------------

.cb-multiselect-checkbox-container {
  .cb-input-helper {
    width: calc(100% - 48px);
  }
  .cb-multiselect-checkbox {
    position: relative;
    .cb-multiselect-checkbox-toggle {
      width: 100%;
      display: block;
      border-radius: 8px;
      border: 1px solid $gray4;
      padding: 11px 15px;
      text-decoration: none;
      position: relative;
      min-height: 48px;
      color: $black1;

      .cb-label {
        transition-property: top, font-size;
        transition-duration: 0.1s;
        transition-timing-function: linear;
      }
      .cb-multiselect-checkbox-toggle-icon {
        position: absolute;
        top: 12px;
        right: 16px;
        font-size: $font-size-small;
        i:not(.cb-x-mark) {
          font-size: 0.6875rem;
          font-weight: 700;
        }
      }
      &:focus {
        border: 1px solid $blue5;
      }
    }
    .cb-multiselect-checkbox-dropdown {
      background: $white;
      border-radius: 0 0 8px 8px;
      padding-bottom: 16px;
      border-top: 1px solid $gray4;
      padding-top: 0;

      .cb-checkbox {
        padding: 8px 16px;
        background: transparent;
        margin-left: 0;

        &:not(.cb-label-left) {
          flex-grow: 1;
          label {
            flex-grow: 1;
            justify-content: flex-start;
          }
        }
        &:hover {
          cursor: pointer;
          border-radius: 0px;
          background: $gray5;
          transition: all 0.2 ease-in-out;
        }
      }

      // TBD condensed
      .cb-multiselect-filter {
        margin: 0;
        padding: 8px 16px 8px 16px;
        border-bottom: 1px solid $gray4;
      }

      .cb-multiselect-checkbox-list {
        max-height: 250px;
        overflow-y: scroll;
        .cb-multiselect-checkbox-all {
          margin-top: 8px;
          padding-bottom: 8px;
          .cb-checkbox {
            margin-bottom: 0;
          }
          + .cb-checkbox {
            margin-top: 8px;
          }
        }
        .cb-no-results {
          margin-top: 0;
          margin: 24px 16px 8px 16px;
          font-size: $font-size-small;
          line-height: 1.71em;
        }
      }
    }
    &.open {
      box-shadow: 0 0 12px rgba($color: $black, $alpha: 0.2);
      border: 1px solid $blue5;
      border-radius: 8px;
      &.cb-validation-state {
        width: calc(100% - 48px);
        .cb-validation-icon {
          display: none;
        }
      }
      .cb-multiselect-checkbox-toggle {
        border-color: $white;
      }

      .cb-multiselect-checkbox-toggle-icon {
        &:hover,
        &:focus {
          border-bottom: 1px solid $black1;
        }
      }

      &.in-modal {
        border: 1px solid $blue5;
        box-shadow: none;
      }
    }

    &.open,
    &.cb-multiselect-checkbox-selected {
      .cb-multiselect-checkbox-toggle {
        > .cb-label {
          z-index: 2;
          position: absolute;
          color: $gray1;
          font-size: $font-size-xsmall;
          line-height: 1.7142857143em;
          font-weight: 500;
          background-color: $white;
          padding: 0 2px;
          left: 14px;
          top: -10px;
        }
      }
    }
    &.open {
      .cb-multiselect-checkbox-toggle {
        > .cb-label {
          color: $blue5;
        }
      }
    }
    &.cb-disabled {
      .cb-multiselect-checkbox-toggle {
        cursor: not-allowed !important;
        color: $cb-disabled-font-color !important;
        .cb-label {
          color: $cb-disabled-font-color !important;
        }
        .cb-icon {
          color: $cb-disabled-font-color !important;
        }
      }
      &:hover {
        cursor: not-allowed !important;
      }
    }
  }
  .cb-tag-set {
    margin-top: 0;
    margin-left: -8px;
    .cb-btn {
      margin-top: 8px;
      margin-left: 8px !important;
    }
  }
  .cb-divider {
    border-bottom: 1px solid $gray4;
    .cb-group-title {
      font-size: 1rem;
      line-height: 1.5em;
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 24px;
      font-weight: 700;
      + .cb-group-desc {
        margin-top: 0;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 8px;
        font-size: $font-size-small;
        line-height: 1.71em;
      }
      + .cb-checkbox {
        margin-top: 8px;
      }
    }
    &.cb-multiselect-grouped-checkbox {
      border-bottom: 0!important;
    }
  }
  .cb-multiselect-checkbox-header {
    font-size: $font-size-xsmall;
    line-height: 1.3333333333em;
    color: $gray2;
    font-weight: 700;
  }
  .cb-multiselect-checkbox-list {
    fieldset {
      .cb-divider {
        .cb-checkbox {
          + .cb-checkbox {
            margin-top: 8px;
          }
          &:last-of-type {
            margin-bottom: 24px;
          }
        }
      }
      &:last-of-type {
        .cb-divider {
          border-bottom: 0;
          .cb-checkbox {
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .cb-multiselect-checkbox-all-text {
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    font-size: $font-size-small;
    line-height: 1.71em;
  }
  .cb-multiselect-grouped-checkbox {
    .cb-group-desc {
      margin-left: 48px;
    }
    .cb-checkbox:not(.cb-checkbox-nested) {
      padding-left: 48px!important;
    }
  }
  &.cb-condensed {
    height: 2.5rem;
    min-height: 2.5rem;
    padding: 7px 15px;
    p {
      font-size: 0.875rem;
      line-height: 1.7142857143em;
    }
    .cb-multiselect-checkbox {
      .cb-multiselect-checkbox-toggle {
        height: 2.5rem;
        min-height: 2.5rem;
        padding: 7px 15px !important;
        .cb-multiselect-checkbox-toggle-icon {
          top: 8px;
          .cb-icon-16 {
            font-size: 0.875rem;
          }
        }
      }
    }
    .cb-divider {
      .cb-group-title {
        + {
          .cb-checkbox {
            margin-top: 4px;
          }
        }
        margin-top: 12px;
      }
    }
    .cb-multiselect-grouped-checkbox{
      .cb-group-desc {
        margin-left: 36px;
      }
      .cb-checkbox:not(.cb-checkbox-nested) {
        padding-left: 36px!important;
      }
    }
  }
}
