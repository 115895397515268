// -----------------------------
// CB Opacity & Tint/Shades
// -----------------------------

@import "apricot-variable.scss";

// ------------------------------------ OPACITY
@for $i from 0 through 9 {
  .cb-opacity-#{$i} {
    opacity: 0.1 * $i;
  }
}

.cb-no-opacity {
  opacity: 1;
}

// ------------------------------------ TINT
.cb-tint-blue5-1,
.cb-blue5-tint-1 {
  background-color: $blue5Tint1!important;
}
.cb-tint-blue5-2,
.cb-blue5-tint-2 {
  background-color: $blue5Tint2!important;
}



@each $key, $value in $tintColors {
  .cb-tint-#{$key}-90,
  .cb-#{$key}-tint-1 {
    background-color: mix(white, $value, 90%)!important;
  }
}

@each $key, $value in $tintColors {
  .cb-tint-#{$key}-95,
  .cb-#{$key}-tint-2 {
    background-color: mix(white, $value, 95%)!important;
  }
}

// ------------------------------------ SHADE

.cb-shade-blue5-1,
.cb-blue5-shade-1 {
  background-color:$blue5-shade1!important;
}
.cb-shade-blue5-2,
.cb-blue5-shade-2 {
  background-color: $blue5-shade2!important;
}

@each $key, $value in $tintColors {
  .cb-shade-#{$key}-20,
  .cb-#{$key}-shade-1 {
    background-color: mix(black, $value, 20%)!important;
  }
}

@each $key, $value in $tintColors {
  .cb-shade-#{$key}-50,
  .cb-#{$key}-shade-2 {
    background-color: mix(black, $value, 50%)!important;
  }
}
