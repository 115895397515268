// -----------------------------
// CB Typography
// -----------------------------

@each $type in $textAlign {
  .cb-align-#{$type} {
    text-align: $type;
  }

  .cb-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @each $value in $white-space {
    .cb-white-space-#{$value}{
      white-space: $value;
    }
  }

  @each $value in $overflow-wrap {
    .cb-overflow-wrap-#{$value}{
      overflow-wrap: $value;
    }
  }

  @each $breakpoint, $value in $grid-breakpoints {
    @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
      // ------------------------------------ ALIGNMENT

      .cb-align-#{$breakpoint}-#{$type} {
        text-align: $type !important;
      }

      // ------------------------------------ TRUNCATE
      .cb-truncate-#{$breakpoint} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // ------------------------------------ WHITE SPACE
      @each $value in $white-space {
        .cb-white-space-#{$breakpoint}-#{$value} {
          white-space: $value;
        }
      }

      // ------------------------------------ OVERFLOW WRAP
      @each $value in $overflow-wrap {
        .cb-overflow-wrap-#{$breakpoint}-#{$value} {
          overflow-wrap: $value;
        }
      }
    }
  }

  @each $breakpoint, $value in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      // ------------------------------------ ALIGNMENT

      .cb-align-#{$breakpoint}-up-#{$type} {
        text-align: $type !important;
      }

      // ------------------------------------ TRUNCATE
      .cb-truncate-#{$breakpoint}-up {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // ------------------------------------ WHITE SPACE
      @each $value in $white-space {
        .cb-white-space-#{$breakpoint}-up-#{$value}{
          white-space: $value;
        }
      }

      // ------------------------------------ OVERFLOW WRAP
      @each $value in $overflow-wrap {
        .cb-overflow-wrap-#{$breakpoint}-up-#{$value}{
          overflow-wrap: $value;
        }
      }
    }
  }
}

// ------------------------------------  INLINE STYLE

.cb-text-strong,
.cb-text-bold {
  font-weight: $font-weight-bold;
}

.cb-text-italic,
.cb-text-emphasis {
  font-style: italic;
}

.cb-text-sub,
.cb-text-sup {
  position: relative;
  font-size: 0.75rem;
  line-height: 1.263157894736842em;
  vertical-align: baseline;
}

// Subscript
.cb-text-sub {
  bottom: -0.25em;
}

// Superscript
.cb-text-sup {
  top: -0.5em;
}

// ------------------------------------  SPECIAL STYLE

.cb-text-highlight-blue,
mark {
  background: $blue5Tint1;
}

.cb-text-highlight-yellow {
  background-color: $yellow1;
}

.cb-text-overline {
  font-size: 1.1875rem;
  line-height: 1.263157894736842em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.cb-text-overline-small {
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.7142857142857142em;
}

.cb-text-uppercase {
  text-transform: uppercase;
}

.cb-text-caption {
  text-transform: capitalize;
}

.cb-text-reset {
  text-transform: none !important;
}

.cb-text-caption-small {
  font-size: $font-size-small;
  line-height: $line-height-small;
  text-transform: capitalize;
  font-weight: 500;
}

// META TAG REGULAR - ROBOTO 500 - 14/24 - CAPS
// META TAG SMALL - ROBOTO 500 - 12/? - CAPS
.cb-text-meta-large {
  @include cb-text-meta-large();
}

.cb-text-meta {
  @include cb-text-meta();
}

.cb-text-super-event {
  padding: 8px 16px;
  border: 1px solid $black1;
  white-space: nowrap;
}

.cb-text-line-through {
  text-decoration: line-through;
}

// ------------------------------------ WEIGHT

@each $key, $value in $fontWeight {
  // .cb-font-weight-#{$key} {
  .#{"cb-font-weight-" + $key} {
    font-weight: $value !important;
  }
}

// ------------------------------------ WEIGHT
@each $key, $weight in $fontWeight {
  @each $breakpoint, $value in $grid-breakpoints {
    @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
      // .cb-font-weight-#{$breakpoint}-#{$key} {
      .#{"cb-font-weight-" + $breakpoint + "-" + $key} {
        font-weight: $weight !important;
      }
    }
  }

  @each $breakpoint, $value in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      .#{"cb-font-weight-" + $breakpoint + "-up-" + $key} {
        font-weight: $weight !important;
      }
    }
  }

  @each $breakpoint, $value in $grid-breakpoints {
    @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
      .#{"cb-font-weight-" + $breakpoint + "-down-" + $key} {
        font-weight: $weight !important;
      }
    }
  }
}

// ------------------------------------ BODY

p {
  font-size: 1rem;
  line-height: 1.5em;
}

@for $i from 1 through 6 {
  .cb-body#{$i},
  .cb-paragraph#{$i}-st {
    font-size: map-get(map-get($body, $i), size);
    line-height: map-get(map-get($body, $i), line);
    font-weight: map-get(map-get($body, $i), weight);
  }
}

// ------------------------------------ PARAGRAPH
.cb-paragraph1-res,
.cb-paragraph1-res > p:not([class]) {
  // xs
  font-size: 1rem;
  line-height: 1.5em;

  // lg and up
  @include media-breakpoint-up("lg", $grid-breakpoints) {
    font-size: 1.3125rem; //21
    line-height: 1.5238095238095237em; //32
  }
}

.cb-paragraph2-res,
.cb-paragraph2-res > p:not([class]) {
  @include cb-paragraph2-res();
}

.cb-paragraph3-res,
.cb-paragraph3-res > p:not([class]) {
  // xs
  font-size: 1.3125rem; //21
  line-height: 1.5238095238095237em; //32

  // lg and up
  @include media-breakpoint-up("lg", $grid-breakpoints) {
    font-size: 2rem; //32
    line-height: 1.5em; //48
  }
}

.cb-paragraph4-res,
.cb-paragraph4-res > p:not([class]) {
  font-size: 2.5rem; //40
  line-height: 1.2em; //48

  @include media-breakpoint-down("sm", $grid-breakpoints) {
    font-size: 2rem; //32
    line-height: 1.25em; //40
  }
}

// ------------------------------------ HEADING
// xs
@for $i from 1 through 6 {
  h#{$i},
  .cb-h#{$i} {
    font-family: $font-family-h;
    font-size: map-get(map-get($heading, h#{$i}), size);
    line-height: map-get(map-get($heading, h#{$i}), line);
  }
}

h1,
.cb-h1 {
  letter-spacing: -1.5px;
}

h2,
.cb-h2 {
  letter-spacing: -0.5px;
}

// sm, md
@include media-breakpoint-up("sm", $grid-breakpoints) {
  h1,
  .cb-h1 {
    font-size: 2.5rem; //40
    line-height: 1.2em; //48
  }

  h2,
  .cb-h2 {
    font-size: 2rem; //32
    line-height: 1.25em; //40
  }

  h3,
  .cb-h3 {
    font-size: 1.75rem; //28
    line-height: 1.1428571428571428em; //32
  }

  h4,
  .cb-h4 {
    font-size: 1.3125rem; //21
    line-height: 1.1428571428571428em; //24
  }

  h5,
  .cb-h5 {
    font-size: 1.125rem; //18
    line-height: 1.3333333333333333em; //24
  }

  h6,
  .cb-h6 {
    font-size: 1rem; //16
    line-height: 1.5em; //24
  }
}

//lg, xl, 2xl, 3xl
@include media-breakpoint-up("lg", $grid-breakpoints) {
  h1,
  .cb-h1 {
    font-size: 3.5rem; //56
    line-height: 1.1428571428571428em; //64
  }

  h2,
  .cb-h2 {
    font-size: 2.5rem; //40
    line-height: 1.2em; //48
  }

  h3,
  .cb-h3 {
    font-size: 2rem; //32
    line-height: 1.25em; //40
  }

  h4,
  .cb-h4 {
    font-size: 1.625rem; //26
    line-height: 1.2307692307692308em; //32
  }

  h5,
  .cb-h5 {
    font-size: 1.3125rem; //21
    line-height: 1.1428571428571428em; //24
  }

  h6,
  .cb-h6 {
    font-size: 1.1875rem; //19
    line-height: 1.263157894736842em; //24
  }
}

.cb-h1-lite {
  font-size: 2rem; //32
  line-height: 1.25em; //40

  // sm, md
  @include media-breakpoint-up("sm", $grid-breakpoints) {
    font-size: 2.5rem; //40
    line-height: 1.2em; //48
  }

  //lg, xl, 2xl, 3xl
  @include media-breakpoint-up("lg", $grid-breakpoints) {
    font-size: 3rem; //48
    line-height: 1.1666666666666667em; //56
  }
}

// ------------------------------------ WRAPPING

.cb-no-text-wrap {
  white-space: nowrap;
}

.cb-text-disabled {
  color: $gray3;
}

.cb-text-placeholder {
  color: $input-placeholder;
}

// ------------------------------------ COLOR PER BREAKPOINT

// Per breakpoint
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .cb-black1-color-#{$breakpoint} {
      color: $black1 !important;
    }

    .cb-white-color-#{$breakpoint} {
      color: $white !important;
    }
  }
}

// ------------------------------------ BLOCKQUOTE

blockquote,
.cb-blockquote {
  margin: 0;
  border-left: 8px solid $blue3;
  padding-left: 32px;
}

// ------------------------------------ UTILITY

.cb-text-author {
  @include roboto-italic();

  &::before {
    content: "\2015";
    padding-right: 8px;
  }
}

.cb-text-fade {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 72px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
}

.cb-word-break {
  word-break: break-all;
}

// ------------------------------------ COLUMN COUNT

@each $size in $columnCount {
  .cb-column-count-#{$size} {
    column-count: $size;
  }
}

@each $size in $columnCount {
  @each $breakpoint, $value in $grid-breakpoints {
    @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
      .cb-column-count-#{$breakpoint}-#{$size} {
        column-count: $size !important;
      }
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      .cb-column-count-#{$breakpoint}-up-#{$size} {
        column-count: $size !important;
      }
    }
  }
}
