// -----------------------------
// CB Input Mask
// -----------------------------

@import "apricot-variable.scss";

.cb-input-mask,
.cb-input-date {
  &.cb-floating-label {
    .cb-input-icon-right {
      label {
        width: calc(100% - 50px);
        justify-content: flex-start;
      }
    }
    input {
      &::placeholder {
        color: transparent;
        transition: color $transition-time-l ease;
      }
    }

    .cb-value-fl,
    .cb-focus-fl {
      width: auto;
      justify-content: center;
      + input {
        &::placeholder {
          color: $input-placeholder;
        }
      }
    }
    .cb-input-icon-right {
      .cb-value-fl,
      .cb-focus-fl {
        width: auto;
        justify-content: center;
      }
    }
  }
}