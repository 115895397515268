.cb-stepper-input {
  position: relative;
  max-width: fit-content;
  
  input {
    padding-right: 40px!important;
    padding-left: 40px!important;
    text-align: center;
    z-index: 1;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
    max-width: 110px;
    width: 110px;
  }
  &.cb-stepper-input-3 {
    input {
      max-width: 120px;
      width:120px
    }
  }
  &.cb-stepper-input-4 {
    input {
      max-width: 130px;
      width:130px
    }
  }
  &.cb-stepper-input-unset {
    input {
      max-width: unset;
      width:unset;
    }
  }

  .cb-btn {
    border: transparent;
    font-size: 1rem;
    line-height: 1em;
    width: 2em;
    height: 2em;
    padding: 0;

    position: absolute;
    top: 8px;
    z-index: 2;

    &:first-of-type {
      left: 8px;
    }
    &:last-of-type {
      right: 8px;
    }
    .cb-icon {
        position: relative;
        color: $gray1;
        transition: color $transition-time-l ease;
        padding: 0;
        top: 0;
        left: 0;
      }

    &:hover {
      background: transparent;
      box-shadow: none;
    }
    &:disabled {
      &:hover {
        background-color: $gray5;
        border-color: $gray5;
      }
    }
    &:focus {
      .cb-icon {
        color: $blue5;
      }

      box-shadow: none;
      background: transparent;
    }
  }

  .cb-validation-label-input {
    width: auto;
  }
  &.cb-input-condensed,
  &.cb-condensed {
    .cb-btn {
      width: 1.5em;
      height: 1.5em;

      .cb-icon {
        font-size: $font-size-small !important;
      }
    }
  }

  &.cb-disabled {
    .cb-btn {
      background: transparent;
      .cb-icon {
        color: $gray2;
      }
    }
  }
}

label {
  &.cb-condensed {
    font-size: $font-size-small;
    line-height: 1.7142857142857142em;
  }
  &.cb-disabled {
      color: $gray2 !important;
  }
}