// -----------------------------
// CB Accordion Mixins
// -----------------------------

@mixin accordion-active-trigger {
  .line {
    &.top {
      transform: rotate(360deg);
    }

    &.bottom {
      transform: rotate(360deg);
    }
  }

  // A11Y
  @media (prefers-reduced-motion: reduce) {
    .line {
      &.top {
        display: none;
      }

      &.bottom {
        transform: rotate(180deg);
      }
    }
  }
}