// -----------------------------
// CB Event Timeline Mixins
// -----------------------------

// ------------- Vertical
@mixin event-cards-vertical() {
  margin-left: 8px;
  @include media-breakpoint-only("xs", $grid-breakpoints) {
    margin-left: 0;
  }

  ol,
  .row {
    flex-direction: column;

    .cb-card {
      padding: 24px;
      background-color: $white;
      border-radius: 16px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
      margin-left: 24px;
      + .cb-card {
        margin-top: 24px;
      }

      position: relative;
      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 2px;
        height: calc(100% + 24px);
        background-color: $gray4;
        top: -24px;
        left: -24px;
      }

      &:first-of-type {
        &::before {
          height: calc(100% - 6px);
          top: 32px;
        }
      }

      &.cb-event-year-stamp {
        margin-top: 48px;
        .cb-event-year {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 32px;
          position: absolute;
          top: -48px;
          left: 0;
        }
      }
      
      + .cb-card {
        &.cb-event-year-stamp {
          margin-top: 92px;

          &::before {
            height: calc(100% + 92px);
            top: -92px;
          }
        }
      }

      &:last-of-type {
        padding-bottom: 24px;
        &::before {
          height: 64px;
        }
        &.cb-event-year-stamp {
          &::before {
            height: 120px;
            top: -92px;
          }
        }
      }

      .cb-card-header {
        .cb-date-time-tags {
          position: relative;
          margin-bottom: 0;
          &::before {
            position: absolute;
            display: block;
            content: "";
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-color: $gray4;
            top: 4px;
            left: -55px;
          }
          p {
            text-transform: uppercase;
          }
        }
      }

      @include media-breakpoint-only("xs", $grid-breakpoints) {
        margin-top: 48px !important;
        margin-left: 0;
        &::before {
          position: absolute;
          display: block;
          content: "";
          width: 2px;
          top: -48px !important;
          height: 48px !important;
          background-color: $gray4;
          left: calc(50% - 1px);
        }
        &::after {
          position: absolute;
          display: block;
          content: "";
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: $gray4;
          top: -32px;
          left: calc(50% - 8px);
        }
        &:first-of-type {
          &:not(.cb-event-year-stamp) {
            margin-top: 0 !important;
          }
          &::before,
          &::after {
            display: none;
          }
        }
        + .cb-card {
          &.cb-event-year-stamp {
            margin-top: 88px !important;
            &::before {
              height: 88px !important;
              top: -88px !important;
            }
            &::after {
              top: -52px;
            }
          }
        }
        .cb-card-header {
          .cb-date-time-tags {
            &::before {
              display: none;
            }
          }
          .cb-card-label,
          .cb-btn-tag {
            width: -moz-fit-content;
            max-width: -moz-fit-content;
          }
        }
      }
    }

    @include media-breakpoint-only("xs", $grid-breakpoints) {
      &.cb-gutterh-24 {
        margin-left: 0;
      }
    }
  }
  // grid width, horizontal - xs
  @include media-breakpoint-only("xs", $grid-breakpoints) {
    .row {
      div[class*="col-"] {
        margin-top: 48px !important;
        .cb-card {
          &::before {
            position: absolute;
            display: block;
            content: "";
            width: 2px;
            top: -48px !important;
            height: 48px !important;
            background-color: $gray4;
            left: calc(50% - 1px);
          }
          &::after {
            position: absolute;
            display: block;
            content: "";
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-color: $gray4;
            top: -32px;
            left: calc(50% - 8px);
          }
        }

        &:first-of-type {
          .cb-card {
            &:not(.cb-event-year-stamp) {
              margin-top: 0 !important;
            }
            &::before,
            &::after {
              display: none;
            }
          }
        }

        + div[class*="col-"] {
          .cb-card {
            &.cb-event-year-stamp {
              margin-top: 40px !important;
              &::before {
                height: 88px !important;
                top: -88px !important;
              }
              &::after {
                top: -52px;
              }
            }
          }
        }
      }
    }
  }
}

// ------------- Horizontal
@mixin event-cards-horizontal() {
  @include media-breakpoint-up("sm", $grid-breakpoints) {
    @include event-cards-horizontal-line();
  }

  @include media-breakpoint-only("xs", $grid-breakpoints) {
    @include event-cards-vertical();
  }
}

@mixin event-cards-horizontal-line() {
  overflow-x: auto;
  flex-wrap: nowrap;

  ol,
  .row {
    flex-wrap: nowrap;
    padding: 8px;
    .cb-card {
      width: 320px;
      min-width: 320px;
      margin-top: 72px;

      position: relative;
      &::before {
        position: absolute;
        display: block;
        content: "";
        height: 2px;
        width: calc(100% + 24px);
        background-color: $gray4;
        top: -24px;
        left: 24px;
      }
      &::after {
        position: absolute;
        display: block;
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: $gray4;
        top: -31px;
        left: 24px;
      }
      &:last-of-type {
        &::before {
          width: 0;
        }
      }
      .cb-card-header {
        flex-flow: column-reverse;
        .cb-card-label,
        .cb-btn-tag {
          margin-bottom: 16px;
          max-width: fit-content;
          display: inline-flex;
        }

        .cb-date-time-tags {
          position: relative;
          margin-bottom: 0;
          p {
            text-transform: uppercase;
          }
        }
      }
      .cb-card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .cb-card-title {
          display: block;
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          + .cb-card-action {
            margin-top: 8px;
          }
        }
      }

      &.cb-event-year-stamp {
        .cb-event-year {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 32px;
          position: absolute;
          top: -72px;
          left: 0;
        }
      }
    }
    &.cb-gutterh-24 {
      margin-left: 0;
      .cb-card {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .cb-has-label {
    .cb-card-content {
      .cb-card-title {
        -webkit-line-clamp: 2 !important;
      }
    }
  }
  .row {
    div[class*="col-"] {
      .cb-card {
        &::before {
          width: calc(100% + 24px);
        }
      }
      &:last-of-type {
        .cb-card {
          &::before {
            width: 0!important;
          }
        }
      }
    }
  }
}
