// -----------------------------
// CB Select
// -----------------------------

.cb-select {
  position: relative;
  width: 100%;

  &.cb-disabled {
    label,
    .cb-glyph,
    .cb-icon {
      color: $gray2 !important;
    }
  }

  label {
    &.cb-disabled {
      color: $gray2;
    }
  }

  .cb-select-container {
    position: relative;
    // width: auto;
    width: 100%;

    select {
      &.cb-replaced {
        position: relative;
        z-index: 10;
        width: 100%;
        opacity: 0;
        border: 0;

        height: 3rem;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    span {
      &.cb-select {
        position: absolute;
        z-index: 1;

        top: 0;
        left: 0;
        display: block;
        width: 100%;

        height: 3rem;

        >span {
          display: block;
          line-height: 1.5em;
          @include border-box();

          height: 3rem;
          border: 1px solid $gray4;
          background-color: $white;
          padding: 11px 15px;
          border-radius: $border-radius-input;
        }

        span {
          span {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            position: relative;
            height: 24px;
          }
        }

        .cb-select-arrow {
          position: absolute;
          display: block;
          height: 42px;
          width: 42px;
          font-size: 0.6875rem;
          font-weight: 700;

          top: -9px;
          right: -15px;

          &::before {
            position: absolute;
            top: 17px;
            left: 13px;
          }
        }

        &.focus {
          >span {
            border: 1px solid $input-focus;
            box-shadow: inset 0px 0px 1px .5px rgba(48, 73, 207, 1);
            padding: 11px 15px;
          }
        }

        // Disabled
        &.disabled {
          >span {
            cursor: default;
            color: $gray2;
            opacity: 1; // iOS fix for unreadable disabled content
          }
        }
      }
    }
  }
  &.cb-not-active {
    option[disabled][hidden] {
      display: none;
      visibility: hidden;
    }
  }
}

// ------------------------------------ CONDENSED
.cb-input-condensed,
.cb-condensed {
  font-size: $font-size-small;
  line-height: 1.7142857142857142em;

  &.cb-select {  
    .cb-select-container {
      select {
        &.cb-replaced {
          height: 2.5rem;
        }
      }

      span {
        &.cb-select {
          height: 2.5rem;

          >span {
            height: 2.5rem;
            padding: 7px 15px;
          }

          .cb-select-arrow {
            top: -9px;
            right: -15px;
          }

          &.focus {
            >span {
              padding: 7px 15px;
            }
          }
        }
      }
    }
  }
}

// ------------------------------------ Multi Select

.cb-select[multiple] {
  border: 1px solid $gray4;
  background-color: $white;
  padding: 11px 0;
  border-radius: $border-radius-input;
  option {
    padding: 0 15px;
    height: 1.5em;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $gray5;
      box-shadow: inset 2px 0px 0px 0px $black1;
    }
  }
  &:focus {
    border: 1px solid $input-focus;
    box-shadow: inset 0px 0px 1px .5px rgba(48, 73, 207, 1);
  }
}