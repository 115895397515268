// -----------------------------
// CB Spinner
// -----------------------------

@import "apricot-variable.scss";

$deg: 30;

/* All high contrast styling rules */
@mixin msContrast() {
  .cb-spinner {
    > *:not(p) {
      &::before {
        background-color: transparent !important;
      }
    }
    .ms-high-contrast {
      font-size: $font-size-small; //14
      line-height: $line-height-small; //24
      color: $black1;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0;
      + .cb-spinner-indicator {
        margin-top: 8px;
      }
    }
  }
}

.cb-spinner {
  width: 4rem;
  height: 4rem;
  position: relative;

  > *:not(p) {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &::before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 12%;
      height: 12%;
      background-color: $black1;
      border-radius: 100%;
      animation: cb-spinnerBounceDelay 1.2s infinite ease-in-out both;

      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
    }

    &:nth-child(2) {
      transform: rotate(30deg);

      &::before {
        animation-delay: -1.1s;
      }
    }

    &:nth-child(3) {
      transform: rotate(60deg);

      &::before {
        animation-delay: -1s;
      }
    }

    &:nth-child(4) {
      transform: rotate(90deg);

      &::before {
        animation-delay: -0.9s;
      }
    }

    &:nth-child(5) {
      transform: rotate(120deg);

      &::before {
        animation-delay: -0.8s;
      }
    }

    &:nth-child(6) {
      transform: rotate(150deg);

      &::before {
        animation-delay: -0.7s;
      }
    }

    &:nth-child(7) {
      transform: rotate(180deg);

      &::before {
        animation-delay: -0.6s;
      }
    }

    &:nth-child(8) {
      transform: rotate(210deg);

      &::before {
        animation-delay: -0.5s;
      }
    }

    &:nth-child(9) {
      transform: rotate(240deg);

      &::before {
        animation-delay: -0.4s;
      }
    }

    &:nth-child(10) {
      transform: rotate(270deg);

      &::before {
        animation-delay: -0.3s;
      }
    }

    &:nth-child(11) {
      transform: rotate(300deg);

      &::before {
        animation-delay: -0.2s;
      }
    }

    &:nth-child(12) {
      transform: rotate(330deg);

      &::before {
        animation-delay: -0.1s;
      }
    }
  }
  p {
    font-size: $font-size-xsmall;
    line-height: 64px;
    margin-left: 11px;
  }

  &.cb-spinner-light {
    > * {
      &::before {
        background-color: $white;
      }
    }
  }

  @each $key, $value in $paletteColors {
    &.cb-palette-#{$key} {
      > *:not(p) {
        &::before {
          background-color: $value;
        }
      }
      .cb-spinner-indicator {
        color: $value;
      }
    }
  }
}

// ------------------------------------ SIZE

$spinnerSize: (16, 24, 32, 64);
@each $size in $spinnerSize {
  .cb-spinner {
    &.cb-spinner-#{$size} {
      width: #{$size}px;
      height: #{$size}px;
    }
  }
}

// ------------------------------------ LABEL
.cb-spinner,
.cb-progress-spinner {
  .cb-spinner-indicator {
    font-size: $font-size-small; //14
    line-height: $line-height-small; //24
    color: $black1;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.cb-progress-spinner {
  height: 4rem;
  width: 4rem;
  position: relative;
  div {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
  }
  .cb-spinner-indicator {
    position: absolute;
    border-radius: 50%;
    top: 8px;
    left: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    background-color: $white;
  }
  .cb-spinner-background {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 100%;
    background-color: $gray4;
  }
  .cb-spinner-rotate {
    clip: rect(0 32px 4rem 0);
    background-color: $black1;
  }
  .cb-spinner-left {
    clip: rect(0 32px 64px 0);
    opacity: 1;
    background-color: $gray4;
    box-shadow: 0px 0px 0px 2px #ffffff;
  }
  .cb-spinner-right {
    clip: rect(0 32px 64px 0);
    transform: rotate(180deg);
    opacity: 0;
    background-color: $black1;
  }
  &.cb-spinner-light {
    .cb-spinner-background,
    .cb-spinner-left {
      background-color: mix(black, $white, 50%);
      box-shadow: none;
    }
    .cb-spinner-rotate,
    .cb-spinner-right {
      background-color: $white;
    }
    .cb-spinner-indicator {
      color: $white;
    }
  }

  @each $key, $value in $paletteColors {
    &.cb-palette-#{$key} {
      .cb-spinner-background,
      .cb-spinner-left {
        background-color: mix(white, $value, 90%);
      }
      .cb-spinner-rotate,
      .cb-spinner-right {
        background-color: $value;
      }
      .cb-spinner-indicator {
        color: $value;
      }
    }
    &.cb-palette-blue5 {
      &::before {
        background-color: #e6edf8;
      }
      .cb-spinner-left {
        background-color: #e6edf8;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active) {
  @include msContrast();
}
@media (forced-colors: active) {
  @include msContrast();
}

// Spinner
@-webkit-keyframes cb-spinnerBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
@keyframes cb-spinnerBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

// Progress
@keyframes toggle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}