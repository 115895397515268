// -----------------------------
// CB File Upload
// -----------------------------

@import "../mixins/link.scss";

.cb-file-upload {
  input[type="file"] {
    display: none;
    // display:none on the input won't trigger the click event in WebKit.
    // Setting visibility to hidden and width 0 works.
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .cb-file-element {
    white-space: nowrap;
    line-height: 24px;
    display: block;
    margin-top: 24px;
  }

  .cb-file-element-rm {
    margin-top: 24px;
    display: block;
    max-width: fit-content;
    white-space: nowrap;
    text-decoration: none;
    @include black-link();
    &.cb-glyph {
      &::before {
        content: "";
      }
      &::after {
        font-size: 0.75rem;
        content: "\e955";
        padding-left: 16px;
      }
      cursor: pointer;
    }

    &.cb-icon {
      &::before {
        content: "";
      }
      &::after {
        font-size: 0.75rem;
        content: "\e999";
        padding-left: 16px;
      }
      cursor: pointer;
    }
    .cb-file-element {
      margin-top: 0;
      @include roboto();
      display: inline-block;
      text-decoration: none;
    }

    &.cb-glyph,
    &.cb-icon {
      &:hover {
        .cb-file-element {
          text-decoration: underline;
        }
      }
    }
  }

  .cb-file-name {
    word-break: break-word;
  }
}
