// -----------------------------
// CB Toast Mixins
// -----------------------------


/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
@mixin react-transition-phase(
  $name,
  $phase,
  $properties,
  $duration: $toast-transition-duration,
  $easing: $toast-transition-ease,
  $delay: 0,
  $before: "",
  $after: ""
) {
  $start-index: 1;
  $end-index: 2;

  @if ($phase == "exit") {
    $start-index: 2;
    $end-index: 1;
  } 

  #{transition-name($phase, $name, $before, $after)} {
    @include each-prop($properties, $start-index);
  }

  #{transition-name(#{$phase}-active, $name, $before, $after)} {
    @include each-prop($properties, $end-index);
    transition-delay: $delay;
    transition-duration: $duration;
    transition-property: map-keys($properties);
    transition-timing-function: $easing;
  }
}

/*
Format transition class name with all the bits.
*/
@function transition-name($phase, $name, $before, $after) {
    @return "#{$before}.#{$name}-#{$phase}#{$after}";
}

/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
@mixin each-prop($properties, $index) {
  @each $prop, $values in $properties {
    #{$prop}: nth($values, $index);
  }
}